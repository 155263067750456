<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}小白單訊息`"
    width="880px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="discount-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="event_name" style="grid-area: 1/1/2/3">
        <template #label>
          <span class="label">活動名稱</span>
        </template>
        <el-input v-model="ruleForm.event_name" placeholder="請輸入活動名稱" />
      </el-form-item>
      <el-form-item class="form-item" prop="memo" style="grid-area: 1/3/4/5">
        <template #label>
          <span class="label">活動說明</span>
        </template>
        <el-input v-model="ruleForm.memo" type="textarea" :rows="11" placeholder="請輸入活動說明" resize="none" />
      </el-form-item>
      <!-- 門市 -->
      <el-form-item class="form-item" prop="store_list_type" style="grid-area: 2/1/3/3">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.store_list_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.store_list_type === 2"
          v-model="ruleForm.store_area"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.store_list_type === 3"
          v-model:file_url="ruleForm.store_file_url"
          v-model:file_name="ruleForm.store_file_name"
        ></upload-file>
      </el-form-item>
      <!-- 日期 -->
      <el-form-item class="form-item" prop="date_type" style="grid-area: 3/1/4/3">
        <template #label>
          <span class="label">日期</span>
          <el-radio-group
            v-model="ruleForm.date_type"
            style="margin-left: 15px; transform: translateY(1px)"
            @change="clearItemValidate(ruleFormRef, 'date_type')"
          >
            <el-radio v-for="item in time_period_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-date-picker
          v-if="ruleForm.date_type === 2"
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <!-- 單選：文字內容 or  圖片內容 -->
      <el-form-item style="grid-area: 4/1/5/5; margin-bottom: 0">
        <template #label>
          <el-radio-group v-model="contentOption" class="contentOption">
            <el-radio label="text">
              文字內容
              <div
                v-if="contentOption == 'text'"
                class="fontBtn"
                :class="{ active: ruleForm.content[0].height == true }"
                style="margin-left: 16px"
                @click="changefontStyle(0, 'height')"
              >
                高
              </div>
              <div
                v-if="contentOption == 'text'"
                class="fontBtn"
                :class="{ active: ruleForm.content[0].width == true }"
                @click="changefontStyle(0, 'width')"
              >
                寬
              </div>
              <div
                v-if="contentOption == 'text'"
                class="fontBtn"
                :class="{ active: ruleForm.content[0].bold == true }"
                @click="changefontStyle(0, 'bold')"
              >
                粗
              </div>
              <span class="green-hint">要空一整行時，請斷行後輸入空格</span>
            </el-radio>
            <el-radio class="picture-label" label="picture" disabled>
              圖片
              <span class="green-hint">尺寸：短邊：432 px，上限：*MB</span>
            </el-radio>
          </el-radio-group>
        </template>
      </el-form-item>
      <!-- 文字內容 -->
      <div style="grid-area: 5/1/6/5" class="text-content">
        <el-form-item style="margin-bottom: 15px; position: relative" v-for="(item, i) in ruleForm.content" :key="i">
          <template #label v-if="i != 0">
            <span class="label" style="margin-right: 16px">文字內容</span>
            <div class="fontBtn" :class="{ active: item.height == true }" @click="changefontStyle(i, 'height')">高</div>
            <div class="fontBtn" :class="{ active: item.width == true }" @click="changefontStyle(i, 'width')">寬</div>
            <div class="fontBtn" :class="{ active: item.bold == true }" @click="changefontStyle(i, 'bold')">粗</div>
            <div class="deleteBtn" @click="deleteTextBlock(i)">刪除</div>
          </template>
          <el-input
            v-model="ruleForm.content[i].message"
            type="textarea"
            :rows="5"
            placeholder="請輸入文字內容"
            resize="none"
          />
        </el-form-item>
        <div class="addTextBlockBtn" @click="addTextBlock">新增文字區段</div>
      </div>
      <!-- QR Code -->
      <el-form-item class="form-item" prop="QRcode_type" style="grid-area: 6/1/7/3">
        <template #label>
          <span class="label">QR Code</span>
          <el-radio-group v-model="ruleForm.QRcode_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in qrcode_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-input v-if="ruleForm.QRcode_type === 2 || ruleForm.QRcode_type === 3" v-model="ruleForm.QR_code_url" />
      </el-form-item>
      <!-- 列印時機 -->
      <el-form-item class="form-item" prop="print_time" style="grid-area: 6/3/7/5">
        <template #label>
          <span class="label">列印時機</span>
          <el-radio-group v-model="ruleForm.print_time" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in printing_time_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-input v-if="ruleForm.print_time === 2" v-model="ruleForm.print_value" type="number">
          <template #append>元</template>
        </el-input>
      </el-form-item>
      <!-- 列印條件 -->
      <div class="title" style="grid-area: 7/1/8/5">
        <span>列印條件</span>
        <el-divider />
      </div>
      <el-form-item class="form-item" prop="source_platform" style="grid-area: 8/1/9/3">
        <template #label>
          <span class="label">訂單來源</span>
        </template>
        <el-select
          v-model="ruleForm.source_platform"
          multiple
          placeholder="請選擇來源"
          style="width: 100%"
          @change="handleSelectSource"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.source_platform, source_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in source_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="take_away_method" style="grid-area: 8/3/9/5">
        <template #label>
          <span class="label">訂單類型</span>
        </template>
        <el-select
          v-model="ruleForm.take_away_method"
          multiple
          placeholder="請選擇訂單類型"
          style="width: 100%"
          @change="handleSelectMethod"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.take_away_method, take_away_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in take_away_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="customer_type" style="grid-area: 9/1/10/3">
        <template #label>
          <span class="label">消費者類型</span>
        </template>
        <el-select
          v-model="ruleForm.customer_type"
          multiple
          placeholder="請選擇消費者類型"
          style="width: 100%"
          @change="handleSelectConsumer"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.customer_type, consumer_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in consumer_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, watch, defineAsyncComponent, getCurrentInstance, reactive } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useActions } from '@/utils/mapStore'; // useGetters  useMutations
import {
  time_period_type_options,
  available_shop_type_options,
  qrcode_options,
  printing_time_options,
  area_options,
  source_options,
  take_away_options,
  consumer_options,
} from '@/utils/define';
export default {
  name: 'news-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    const selectEventRuleId = ref(null);
    const selectEventRuleSize = ref('');
    const contentOption = ref('text'); //是文字或圖片

    //--------------------------------------------------------------------
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        // console.log('ruleForm.value ',ruleForm.value )
        //日期格式
        if (ruleForm.value.date_type === 2) {
          datetimeRange.value = [];
          ruleForm.value.start_date = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.start_date);
          ruleForm.value.end_date = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.end_date);
          datetimeRange.value.push(ruleForm.value.start_date);
          datetimeRange.value.push(ruleForm.value.end_date);
        }
      } else
        ruleForm.value = {
          event_id: null,
          event_name: '', //活動名稱
          store_list_type: 1, //門市
          store_area: [], //指定區域
          store_file_url: '', //門市清單csv檔
          store_file_name: '', //檔名
          date_type: 1, //日期
          start_date: '', //開始日期
          end_date: '', //結束日期
          QRcode_type: 1, //QRcode
          QR_code_url: '', //QRcode URL
          print_time: 1, //列印時機
          print_value: 0, //列印時機金額設定
          source_platform: [], // 訂單來源
          take_away_method: [], // 訂單類型
          customer_type: [], //消費者類型
          media_url: '', //圖片路徑
          media_name: '', //圖片名稱
          content: [
            //小白單訊息
            {
              height: false,
              width: false,
              bold: false,
              message: '',
            },
          ],
          memo: '',
        };
    }

    //----------------------------------------------------------------------
    // 文字內容相關事件
    const changefontStyle = (i, attr) => {
      if (ruleForm.value.content[i][attr]) ruleForm.value.content[i][attr] = false;
      else ruleForm.value.content[i][attr] = true;
    };
    const addTextBlock = () => {
      ruleForm.value.content.push({
        height: false,
        width: false,
        bold: false,
        message: '',
      });
    };
    const deleteTextBlock = (i) => {
      proxy
        .$confirm(`是否確認刪除此文字區塊？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          ruleForm.value.content.splice(i, 1);
        })
        .catch((e) => e);
    };

    //----------------------------------------------------------------------
    //處理來源跟類型的全選
    const handleSelectSource = (val) => {
      if (val.includes('all')) {
        ruleForm.value.source_platform = [];
        source_options.forEach((e) => {
          ruleForm.value.source_platform.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.source_platform = [];
      }
    };
    const handleSelectMethod = (val) => {
      if (val.includes('all')) {
        ruleForm.value.take_away_method = [];
        take_away_options.forEach((e) => {
          ruleForm.value.take_away_method.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.take_away_method = [];
      }
    };
    const handleSelectConsumer = (val) => {
      if (val.includes('all')) {
        ruleForm.value.customer_type = [];
        consumer_options.forEach((e) => {
          ruleForm.value.customer_type.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.customer_type = [];
      }
    };
    const checkSelectAll = (select_arr, option_arr) => {
      if (select_arr.length === option_arr.length) return true;
      else return false;
    };
    //----------------------------------------------------------------------
    //radio切換-賦予初始值、清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    //切換門市
    watch(
      () => [ruleForm.value.store_list_type, ruleForm.value.store_area, ruleForm.value.store_file_url],
      () => {
        clearItemValidate(ruleFormRef.value, 'store_list_type');
      }
    );

    //切換列印時機
    watch(
      () => [ruleForm.value.print_time],
      () => {
        clearItemValidate(ruleFormRef.value, 'print_time');
        if (ruleForm.value.print_time != 2) ruleForm.value.print_value = 0;
      }
    );

    //切換QRcode
    watch(
      () => [ruleForm.value.QRcode_type],
      () => {
        if (ruleForm.value.QRcode_type != 2 || ruleForm.value.QRcode_type != 3) ruleForm.value.QR_code_url = '';
      }
    );
    //--------------------------------------------------------------------
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.start_date = null;
          ruleForm.value.end_date = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.start_date = datetimeRange.value[0];
          ruleForm.value.end_date = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];
    //--------------------------------------------------------------------
    //驗證
    const validateTime = (rule, value, callback) => {
      if (ruleForm.value.date_type === 1) callback();
      else {
        if (!datetimeRange.value) return callback(new Error('請選擇日期'));
        else callback();
      }
    };
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.store_area?.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.store_file_name || !ruleForm.value.store_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const validateQRcode = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.QR_code_url.trim() === '') return callback(new Error('請填寫網址'));
          else callback();
          break;
        case 3:
          if (ruleForm.value.QR_code_url.trim() === '') return callback(new Error('請填寫序號'));
          else callback();
          break;
      }
    };
    const validatePrintTime = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.print_value <= 0) return callback(new Error('請填寫金額'));
          else callback();
          break;
        case 3:
          callback();
          break;
      }
    };
    const rules = reactive({
      event_name: [{ required: true, message: '請輸入活動名稱', trigger: 'blur' }],
      date_type: [{ validator: validateTime, trigger: 'blur' }],
      store_list_type: [{ validator: validateShop, trigger: 'blur' }],
      QRcode_type: [{ validator: validateQRcode, trigger: 'blur' }],
      print_time: [{ validator: validatePrintTime, trigger: 'blur' }],
      source_platform: [{ required: true, message: '請選擇訂單來源', trigger: 'blur' }],
      take_away_method: [{ required: true, message: '請選擇訂單類型', trigger: 'blur' }],
      customer_type: [{ required: true, message: '請選擇消費者類型', trigger: 'blur' }],
    });
    //--------------------------------------------------------------------
    //送出
    const { doAddNews, doEditNews } = useActions('news', ['doAddNews', 'doEditNews']);
    const handleSubmit = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log('ruleForm--', ruleForm.value);
        if (cusProps.action === 'create') await doAddNews(ruleForm.value);
        else await doEditNews(ruleForm.value);
        proxy.$message({
          type: 'success',
          message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
        });
        submit(cusProps.action);
      } catch (e) {
        e;
      }
    };

    return {
      time_period_type_options,
      available_shop_type_options,
      qrcode_options,
      printing_time_options,
      area_options,
      source_options,
      take_away_options,
      consumer_options,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      datetimeRange,
      contentOption,
      selectEventRuleId,
      selectEventRuleSize,
      clearItemValidate,
      defaultTime,
      handleSelectSource,
      handleSelectMethod,
      handleSelectConsumer,
      checkSelectAll,
      rules,
      handleSubmit,
      changefontStyle,
      addTextBlock,
      deleteTextBlock,
    };
  },
};
</script>
<style lang="scss" scoped>
.discount-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
  min-height: auto;
}
.title {
  margin-top: 20px;
  span {
    color: $color-primary;
    font-size: 1.6rem;
  }
}
.dialog-footer {
  @include full-button;
}
.el-divider--horizontal {
  margin: 4px 0 24px;
}
.contentOption {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-column-gap: 37.5px;
}
.fontBtn {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: $color-background;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  font-weight: normal;
}
.fontBtn.active {
  background-color: #f0f9eb;
  color: $color-green;
}
.deleteBtn {
  color: $color-danger;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.addTextBlockBtn {
  background-color: $color-background;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
  cursor: pointer;
}
:deep(.picture-label .el-radio__label) {
  display: flex;
  align-content: center;
}
.green-hint {
  margin-left: 8px;
}
</style>
